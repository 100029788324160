import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export interface NgVarContext<T = unknown> {
  $implicit: T;
  ngVar: T;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngVar]',
})
export class NgVarDirective<T = unknown> {
  /**
   * Assert the correct type of the expression bound to the `ngVar` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `ngVar` structural directive renders its template, the type of the expression bound
   * to `ngVar` should be narrowed in some way. For `ngVar`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `ngVar`.
   */
  static ngTemplateGuard_ngVar: 'binding';

  /**
   * Asserts the correct type of the context for the template that `ngVar` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `ngVar` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard<T>(dir: NgVarDirective<T>, ctx: unknown): ctx is NgVarContext<T> {
    return true;
  }

  @Input()
  set ngVar(context: T) {
    this.context.$implicit = this.context.ngVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private context: NgVarContext<T> = {
    $implicit: null!,
    ngVar: null!,
  };

  private hasView = false;

  constructor(private templateRef: TemplateRef<unknown>, private vcRef: ViewContainerRef) {}
}
